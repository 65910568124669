import {
  Button,
  Dialog,
  DialogBody,
  DialogHeader,
  DialogFooter,
  DialogBackdrop,
  DialogContent,
  DialogTitle,
  DialogCloseButton,
} from '@lemonenergy/lemonpie-3'
import { Whatsapp } from '@lemonenergy/lemonpie-icons'
import { useLoaderData, Link } from '@remix-run/react'
import { useEffect } from 'react'

import makeWhatsappLink from '~/utils/makeWhatsappLink.util'
import { captureClick, captureVisualization } from '~/utils/tracking'

type DialogProps = {
  isOpen: boolean
  onClose: () => void
}

const FaqDialog = ({ onClose, isOpen }: DialogProps) => {
  const { whatsappNumber } = useLoaderData<{ whatsappNumber: string }>()
  const whastappLink = makeWhatsappLink(
    whatsappNumber,
    'Olá, gostaria de mais informações sobre a Lemon',
  )

  useEffect(() => {
    if (isOpen) {
      captureVisualization('Modal de FAQ')
    }
  }, [isOpen])

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogBackdrop className="[&&]:justify-center">
        <DialogContent className="max-w-[629px]">
          <DialogHeader fixed>
            <DialogTitle>Dúvidas Frequentes</DialogTitle>
            <DialogCloseButton onClick={onClose} aria-label="close-modal" />
          </DialogHeader>
          <DialogBody className="flex flex-col gap-y-large typography-body-small pt-none">
            <div className="flex flex-col gap-y-smallest">
              <strong>Eu tenho que pagar a instalação?</strong>
              <p>
                Não! Com a Lemon você não precisa pagar absolutamente nada para
                começar a ter sua energia limpa. Sem taxas, sem obras ou
                instalações.
              </p>
            </div>

            <div className="flex flex-col gap-y-smallest">
              <strong>Se eu não pagar a conta da Lemon eu fico sem luz?</strong>
              <p>
                Não! O que acontece é que você volta a receber a energia da sua
                distribuidora sem desconto.
              </p>
            </div>

            <div className="flex flex-col gap-y-smallest">
              <strong>Como que a energia chega até aqui em casa?</strong>
              <p>
                Imagine um ecossistema, a nossa usina parceira gera a energia
                limpa e distribui pela rede da sua distribuidora atual que leva
                a energia até a sua a casa. Nada muda no seu dia a dia, sem
                obras ou instalação.
              </p>
            </div>
          </DialogBody>
          <DialogFooter>
            <Button variant="primary" onClick={e => captureClick()(e)} asChild>
              <Link
                target="_blank"
                to={whastappLink}
                className="gap-smallest inline-flex"
              >
                <Whatsapp size="main" /> <span>Falar com a gente</span>
              </Link>
            </Button>
          </DialogFooter>
        </DialogContent>
      </DialogBackdrop>
    </Dialog>
  )
}

export default FaqDialog
