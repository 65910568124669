import { Icon } from '@lemonenergy/lemonpie'
import { Button } from '@lemonenergy/lemonpie-3'
import { BrandSun } from '@lemonenergy/lemonpie-icons'
import type { MetaFunction, LinksFunction } from '@remix-run/node'
import { Link, json } from '@remix-run/react'
import type { BaseSyntheticEvent } from 'react'
import { useState } from 'react'
import { useTransitionMap } from 'react-transition-state'

import TrustedByItem from './__components/TrustedByItem'
import FaqDialog from './cadastro/informacoes-pessoais/__components/FaqDialog'
import HeaderLogo from './cadastro/informacoes-pessoais/__components/HeaderLogo'

import HomeBackground from 'public/images/bg-acquisition-flow-home.svg'
import FormActions from '~/components/form/v2/FormActions'
import constants from '~/constants/index.server'
import paths from '~/constants/paths'
import { trustedByItems, type TrustedByItemsKeys } from '~/constants/trustedBy'
import cacheUtils from '~/utils/cache.utils.server'
import commonMetaTags from '~/utils/commonMetaTags.util'
import { captureClick } from '~/utils/tracking'

export const handle = {
  variant: 'dark',
  isHeaderHidden: true,
  lgpdOptOut: true,
  disableFreshChat: true,
}
export const loader = async () => {
  return json(
    {
      baseUrl: constants.baseUrl,
      whatsappNumber: constants.whatsappNumber,
    },
    {
      headers: cacheUtils.createCacheHeaders({
        cdnStrategy: 'FOR_ONE_QUARTER',
        browserStrategy: 'FOR_ONE_HOUR',
      }),
    },
  )
}

export const meta: MetaFunction<typeof loader> = ({ data }) => {
  const baseUrl = data?.baseUrl
  return commonMetaTags({
    baseUrl,
    title: 'Quero Economizar',
    description:
      'Inicie seu cadastro agora mesmo e garanta mais economia em sua conta de luz.',
  })
}

export const links: LinksFunction = () => {
  return [
    {
      rel: 'preload',
      href: HomeBackground,
      as: 'image',
    },
    ...trustedByItems.map(item => ({
      rel: 'preload',
      href: `${item.src}?height=12`,
      imageSrcSet: `${item.src}?height=12 1x, ${item.src}?height=24 2x, ${item.src}?height=36 3x`,
      as: 'image',
    })),
  ]
}

const AcquisitionFlowHome = () => {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
  const animationTimeout = 3000

  const transition = useTransitionMap<TrustedByItemsKeys>({
    timeout: animationTimeout,
    mountOnEnter: true,
    unmountOnExit: true,
    preEnter: true,
  })

  const onDialogClose = () => {
    captureClick('Fechar modal de FAQ')()
    setIsDialogOpen(false)
  }
  const openDialog = () => {
    captureClick('Ajuda')()
    setIsDialogOpen(true)
  }
  return (
    <>
      <main className="bg-expressive-strong content-grid grid-rows-[4.5rem_minmax(calc(100dvh-4.5rem),auto)] sm:items-start">
        <header className="flex justify-between gap-x-large py-large text-white-100">
          <HeaderLogo variant="dark" logoVariant="normal" />
          <Icon
            name="question-mark"
            size="sm"
            onClick={openDialog}
            aria-label="open-modal"
          />
        </header>
        <section className="col-span-full py-larger content-fullwidth grid-rows-[min-content] sm:content-center">
          <div className="col-[fullwidth] overflow-x-hidden flex justify-center items-center">
            <img
              src={HomeBackground}
              className="w-full min-w-[400px] h-auto aspect-[629/406] max-h-[350px]"
              alt=""
            />
          </div>
          <article className="pt-large sm:pb-large flex flex-col gap-y-small">
            <h3 className="flex gap-x-smallest items-center min-h-large">
              <BrandSun className="text-[#8AFFB6]" size="small" />
              <span className="fg-inverted-main typography-section">
                Quem confia na Lemon
              </span>
              <div
                className="h-24 ml-4 overflow-hidden grid grid-cols-1 *:col-start-1 *:row-start-1"
                data-testid="trusted-by-logos"
              >
                {trustedByItems.map(({ id, ...item }, index) => (
                  <TrustedByItem
                    key={id}
                    itemKey={id}
                    nextItemKey={
                      trustedByItems[(index + 1) % trustedByItems.length].id
                    }
                    initialEntered={index === 0}
                    animationTimeout={animationTimeout}
                    {...item}
                    {...transition}
                  />
                ))}
              </div>
            </h3>
            <h1 className="mt-12 text-24 leading-[1.75rem] -tracking-[0.02rem] font-medium fg-inverted-main">
              Energia limpa sem instalação, sem custo extra e com economia todo
              mês
            </h1>
            <h2 className="typography-section fg-expressive-inverted">
              Vem com a Lemon construir um mundo mais sustentável
            </h2>
          </article>
          <FormActions className="pb-0 pt-24 sm:mb-auto">
            <Button
              onClick={(e: BaseSyntheticEvent) => captureClick()(e)}
              variant="primary"
              inverted
              asChild
              className="justify-between"
            >
              <Link to={paths.ACQUISITION_FLOW_REGISTER_PERSON_DATA}>
                <span>Cadastrar para receber benefício</span>
                <Icon name="arrow-right" size="xs" />
              </Link>
            </Button>
          </FormActions>
        </section>
      </main>
      <FaqDialog isOpen={isDialogOpen} onClose={onDialogClose} />
    </>
  )
}

export default AcquisitionFlowHome
