import { cn } from '@lemonenergy/lemonpie-3'
import { useEffect } from 'react'
import type { TransitionMapResult } from 'react-transition-state'

import type {
  TrustedByItemsValues,
  TrustedByItemsKeys,
} from '~/constants/trustedBy'
type TrustedByItemProps = Omit<TrustedByItemsValues, 'id'> &
  TransitionMapResult<TrustedByItemsKeys> & {
    itemKey: TrustedByItemsKeys
    nextItemKey: TrustedByItemsKeys
    initialEntered?: boolean
    animationTimeout: number
  }
const TrustedByItem = ({
  itemKey,
  src,
  name,
  aspectRatio,
  initialEntered,
  stateMap,
  toggle,
  nextItemKey,
  setItem,
  deleteItem,
  animationTimeout,
}: TrustedByItemProps) => {
  useEffect(() => {
    setItem(itemKey, { initialEntered })
    return () => {
      deleteItem(itemKey)
    }
  }, [setItem, deleteItem, itemKey, initialEntered])

  const { status, isMounted } = stateMap.get(itemKey) || {}

  useEffect(() => {
    if (status === 'entered') {
      const timeout = setTimeout(() => {
        toggle(nextItemKey)
      }, animationTimeout / 2)
      return () => clearTimeout(timeout)
    }
  }, [toggle, nextItemKey, animationTimeout, status])

  if (!isMounted) return null

  const preEnterStyle =
    status === 'preEnter' ? 'translate-y-full opacity-0' : ''

  const enteredStyle =
    status === 'entered' ? 'translate-y-0 opacity-100 duration-1000' : ''

  const preExitStyle = status === 'preExit' ? 'order-1' : ''

  const exitingStyle =
    status === 'exiting' ? 'opacity-0 duration-500 order-1' : ''

  const classList = cn([
    'py-4 px-8 min-h-24 w-fit bg-main rounded-infinite flex items-center order-2 transform transition-all',
    preEnterStyle,
    enteredStyle,
    preExitStyle,
    exitingStyle,
  ])

  return (
    <span className={classList}>
      <img
        src={`${src}?height=12`}
        srcSet={`${src}?height=12 1x, ${src}?height=24 2x, ${src}?height=36 3x`}
        alt={name}
        width={aspectRatio * 12}
        height={12}
        loading="lazy"
        decoding="async"
        className={`aspect-[${aspectRatio}]`}
      />
    </span>
  )
}
export default TrustedByItem
