export const trustedByItems = [
  {
    id: 'ambev',
    src: 'https://images.energialemon.com.br/site/logo-ambev.png',
    name: 'Ambev',
    aspectRatio: 48 / 12,
  },
  {
    id: 'banco-safra',
    src: 'https://images.energialemon.com.br/site/logo-banco-safra.png',
    name: 'Banco Safra',
    aspectRatio: 82.44 / 12,
  },
  {
    id: 'gympass',
    src: 'https://images.energialemon.com.br/site/logo-gympass.png',
    name: 'Gympass',
    aspectRatio: 63.21 / 12,
  },
]

export type TrustedByItems = typeof trustedByItems
export type TrustedByItemsKeys = TrustedByItems[number]['id']
export type TrustedByItemsValues = TrustedByItems[number]
